import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="id-form"
export default class extends Controller {
	static targets = ["documentTypeField", "countryField", "stateField", "tribeField", "otherField"];
	static values = { requiredFields: Boolean };

	connect() {
		this.initializeFields();
	}

	initializeFields() {
		// hide irrelevant country options
		switch (this.selectedDocumentType) {
			case "StateDL":
			case "StateLocalTribeID":
				this.foreignCountries.forEach((element) => (element.hidden = true));
			case "Foreign":
				this.usCountries.forEach((element) => (element.hidden = true));
			case "USPassport":
			default:
				break;
		}

		// enforce us passport
		if (this.selectedDocumentType === "USPassport") {
			this.countryFieldTarget.disabled = true;
			this.countryFieldTarget.value = this.usId;
		}

		// enable if fields are set
		if (this.stateFieldTarget.value !== "") {
			this.stateFieldTarget.disabled = false;
		}
		if (this.tribeFieldTarget.value !== "") {
			this.tribeFieldTarget.disabled = false;
		}
		if (this.otherFieldTarget.value !== "") {
			this.otherFieldTarget.disabled = false;
		}
	}

	selectDocumentType() {
		switch (this.selectedDocumentType) {
			case "StateDL":
				this.enableCountryField(true, { us: true, foreign: false });
				this.enableStateField(true);
				this.enableTribeField(false);
				this.enableOtherField(false);
				break;
			case "StateLocalTribeID":
				this.enableCountryField(true, { us: true, foreign: false });
				this.enableStateField(true);
				this.enableTribeField(true);
				this.enableOtherField(false);
				break;
			case "USPassport":
				this.enableCountryField(false);
				this.countryFieldTarget.value = this.usId;
				this.enableStateField(false);
				this.enableTribeField(false);
				this.enableOtherField(false);
				break;
			case "Foreign":
				this.enableCountryField(true, { us: false, foreign: true });
				this.enableStateField(false);
				this.enableTribeField(false);
				this.enableOtherField(false);
				break;
			default:
				this.enableCountryField(true, { us: true, foreign: true });
				this.enableStateField(true);
				this.enableTribeField(false);
				this.enableOtherField(false);
				break;
		}
	}

	selectCountry() {
		switch (this.selectedCountryType) {
			case "US":
				this.enableStateField(true);
				this.enableTribeField(this.selectedDocumentType === "StateLocalTribeID");
				this.enableOtherField(false);
				break;
			case "Territory":
			case "Foreign":
				this.enableStateField(false);
				this.enableTribeField(false);
				this.enableOtherField(false);
				break;
			default:
				this.enableStateField(true);
				this.enableTribeField(this.selectedDocumentType === "StateLocalTribeID");
				this.enableOtherField(false);
				break;
		}
	}

	selectState() {
		if (this.selectedDocumentType === "StateLocalTribeID") {
			switch (this.stateFieldTarget.value) {
				case "":
					this.enableTribeField(true);
					this.enableOtherField(false);
					break;
				default:
					this.enableTribeField(false);
					this.enableOtherField(false);
					break;
			}
		}
	}

	selectTribe() {
		if (this.selectedTribeElement.innerText.includes("Other")) {
			this.enableStateField(false);
			this.enableOtherField(true);
		} else if (this.selectedTribeElement.value === "") {
			this.enableStateField(true);
			this.enableOtherField(false);
		} else {
			this.enableStateField(false);
			this.enableOtherField(false);
		}
	}

	enableCountryField(enable, { us = true, foreign = true } = {}) {
		this.countryFieldTarget.disabled = !enable;
		this.countryFieldTarget.value = "";
		this.usCountries.forEach((element) => (element.hidden = !us));
		this.foreignCountries.forEach((element) => (element.hidden = !foreign));
	}

	enableStateField(enable) {
		this.stateFieldTarget.disabled = !enable;
		this.stateFieldTarget.value = "";
		if (this.requiredFieldsValue) {
			this.stateFieldTarget.required = enable;
		}
	}

	enableTribeField(enable) {
		this.tribeFieldTarget.disabled = !enable;
		this.tribeFieldTarget.value = "";
		if (this.requiredFieldsValue) {
			this.tribeFieldTarget.required = enable;
		}
	}

	enableOtherField(enable) {
		this.otherFieldTarget.disabled = !enable;
		this.otherFieldTarget.value = "";
		if (this.requiredFieldsValue) {
			this.otherFieldTarget.required = enable;
		}
	}

	get selectedDocumentType() {
		return this.documentTypeFieldTarget.selectedOptions[0].dataset.documentType;
	}

	get selectedCountryType() {
		return this.countryFieldTarget.selectedOptions[0].dataset.countryType;
	}

	get selectedTribeElement() {
		return this.tribeFieldTarget.selectedOptions[0];
	}

	get usId() {
		return this.countryFieldTarget.querySelector("[data-country-type='US']").value;
	}

	get usCountries() {
		return this.countryFieldTarget.querySelectorAll("[data-country-type='US'], [data-country-type='Territory']");
	}

	get foreignCountries() {
		return this.countryFieldTarget.querySelectorAll("[data-country-type='Foreign']");
	}
}
